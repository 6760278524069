<template>
  <div id="login">
    <div class="container">
      <div class="col-lg-6 offset-lg-3 formPadding">
        <form>
          <div class="form-group">
            <label for="exampleInputName">Uživatelské jméno</label>
            <input type="text" class="form-control" id="exampleInputName" v-model="credentials.name"
                   placeholder="Jméno">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Uživatelské heslo</label>
            <input type="password" class="form-control" id="exampleInputPassword1" v-model="credentials.password"
                   placeholder="Heslo">
          </div>
          <div class="text-center pt-lg-4">
            <button type="button" v-on:click="login" class="btn btn-dark">Přihlásit</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from '../../api/axiom-instance'

export default {
  name: "login",
  data() {
    return {
      credentials: {name: "", password: ""}
    }
  },
  methods: {
    login: function () {
      axios.get("/login", {
        params: {
          name: this.credentials.name,
          password: this.credentials.password
        }
      }).then(() => {
        this.$router.push({path: "/admin"})
      }).catch((failed) => {
        console.log(failed)
      })
    }
  }

}
</script>

<style scoped>
#login {
  background-color: #0f2328;
  height: 100vh;
}

.formPadding {
  padding-top: 20vh;
}

form {
  background-color: #e7e7e6;
  padding: 2em 1.5em 3em;
  border-radius: 5px;
}

label {
  color: black;
}

</style>
